import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import TableInfo from '../../components/TableInfo';

import {
    canCopySelector,
    canCreateByFormSelector,
    canDeleteSelector,
    canEditSelector,
    canExportToExcelSelector,
    canImportFromExcelSelector,
    columnsGridSelector,
    deleteDictionaryEntryRequest,
    exportProgressSelector,
    importFromExcelRequest,
    importProgressSelector
} from '../../ducks/dictionaryView';
import {
    getRolesRequest,
    progressSelector,
    rolesListSelector,
    toggleRoleActiveRequest,
    totalCountSelector,
} from '../../ducks/roles';
import { Confirm } from 'semantic-ui-react';
import { NEW_ROLE_LINK, ROLE_LINK } from '../../router/links';
import {
    actionsCardSelector,
    allIdsSelector,
    clearActions,
    getActionsRequest,
    getAllIdsRequest,
    invokeActionRequest,
} from '../../ducks/gridActions';

export class RolesList extends Component {
    state = {
        confirmation: {
            open: false
        }
    };

    closeConfirmation = () => {
        this.setState({
            confirmation: {
                open: false,
            }
        });
    };

    showConfirmation = (content, onConfirm, onCancel) => {
        this.setState({
            confirmation: {
                open: true,
                content,
                onConfirm,
                onCancel,
            }
        });
    };


    handleToggleIsActive = (itemID, checked, load) => {
        const { toggleActive } = this.props;

        toggleActive({
            id: itemID,
            active: checked,
            callbackSuccess: () => {
                load(false, true);
            },
        });
    };

    handleImportFromExcel = (form, callbackSuccess) => {
        const { importFromExcel, match } = this.props;
        const { params = {} } = match;
        const { name = 'roles' } = params;

        const callbackConfirmation = (model) => {
            this.showConfirmation(
                model.confirmationMessage || model.message,
                () => {
                    this.closeConfirmation();

                    importFromExcel({
                        form,
                        name,
                        callbackSuccess,
                        isConfirmed: true
                    });
                },
                this.closeConfirmation
            )
        };

        importFromExcel({
            form,
            name,
            callbackSuccess,
            isConfirmed: false,
            callbackConfirmation
        });
    };

    handleDeleteEntries = (form, callbackSuccess) => {
        const { t, deleteEntries, match } = this.props;
        const { params = {} } = match;
        const { name = 'roles' } = params;

        this.showConfirmation(
            t('confirm_delete_entries').replace(':DICT', t(name)),
            () => {
                this.closeConfirmation();
                deleteEntries({
                    ...form, 
                    callbackSuccess
                });
            },
            this.closeConfirmation,
        );
    };

    render() {
        const {
            columns,
            list,
            loadList,
            totalCount,
            loading,
            t,
            isCreateBtn,
            isCopyBtn,
            isImportBtn,
            isExportBtn,
            isDeleteBtn,
            canEdit,
            importLoader,
            exportLoader,
            clear,
            getActions,
            invokeAction,
            getAllIds,
            clearActions,
            allIds,
        } = this.props;

        return (
            <>
                <TableInfo
                    headerRow={columns}
                    name="roles"
                    loading={loading}
                    className={
                        columns.length >= 10
                            ? 'container'
                            : 'wider ui container container-margin-top-bottom'
                    }
                    list={list}
                    toggleIsActive={this.handleToggleIsActive}
                    clear={clear}
                    storageSortItem={`rolesSort`}
                    storageFilterItem={`rolesFilters`}
                    totalCount={totalCount}
                    loadList={loadList}
                    isCopyBtn={isCopyBtn}
                    isDeleteBtn={isDeleteBtn}
                    isImportBtn={isImportBtn}
                    isExportBtn={isExportBtn}
                    importFromExcel={this.handleImportFromExcel}
                    importLoader={importLoader}
                    exportLoader={exportLoader}
                    newLink={isCreateBtn ? NEW_ROLE_LINK : null}
                    cardLink={ROLE_LINK}
                    getActions={getActions}
                    deleteEntries={this.handleDeleteEntries}
                    getAllIds={getAllIds}
                    invokeAction={invokeAction}
                    clearActions={clearActions}
                    allIds={allIds}
                    groupActions={this.getGroupActions}
                    canEdit={canEdit}
                />
                <Confirm
                    dimmer="blurring"
                    open={this.state.confirmation.open}
                    onCancel={this.state.confirmation.onCancel || this.closeConfirmation}
                    cancelButton={t('cancelConfirm')}
                    confirmButton={t('Yes')}
                    onConfirm={this.state.confirmation.onConfirm}
                    content={this.state.confirmation.content}
                />
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { match = {} } = ownProps;
    const { params = {} } = match;
    const { name = 'roles' } = params;

    return {
        columns: columnsGridSelector(state, name),
        list: rolesListSelector(state),
        totalCount: totalCountSelector(state),
        loading: progressSelector(state),
        isCopyBtn: canCopySelector(state, name),
        isDeleteBtn: canDeleteSelector(state, name),
        isCreateBtn: canCreateByFormSelector(state, name),
        isImportBtn: canImportFromExcelSelector(state, name),
        isExportBtn: canExportToExcelSelector(state, name),
        canEdit: canEditSelector(state, name),
        importLoader: importProgressSelector(state),
        exportLoader: exportProgressSelector(state),
        allIds: allIdsSelector(state),
        actions: actionsCardSelector(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadList: params => {
            dispatch(getRolesRequest(params));
        },
        importFromExcel: params => {
            dispatch(importFromExcelRequest(params));
        },
        toggleActive: params => {
            dispatch(toggleRoleActiveRequest(params));
        },
        getActions: params => {
            dispatch(getActionsRequest(params));
        },
        invokeAction: params => {
            dispatch(invokeActionRequest(params));
        },
        deleteEntries: params => {
            dispatch(deleteDictionaryEntryRequest(params));
        },
        getAllIds: params => {
            dispatch(getAllIdsRequest(params));
        },
        clearActions: params => {
            dispatch(clearActions(params));
        },
    };
};

export default withTranslation()(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(RolesList),
);
